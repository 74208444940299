/* eslint-disable @typescript-eslint/no-unused-vars */
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import {
  CookieBanner,
  Card,
  Footer,
  NavBar,
  InstallBanner,
  useAddToHomescreenPrompt,
} from '@uniqs-labs/ui-react-lib'
import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import styles from '../styles/components/Layout.module.css'
import AppIcon from '../assets/images/icon-512x512.png'

const QRCode = dynamic(() => import('./QrCode'), {
  ssr: false,
})

export default function Layout({ children }: any) {
  const { t } = useTranslation(['layout', 'common', 'app-install'])
  const router = useRouter()
  const currentOrigin = typeof window !== 'undefined' ? window.location.origin : ''
  const [promptable, promptToInstall, isInstalled] = useAddToHomescreenPrompt()
  const [collapseStatus, setCollapseStatus] = useState(true)
  const [appStatus, setAppStatus] = useState(false)
  const [disabledClick, setDisabledClick] = useState(false)

  // define in logging hook

  const handleClick = (e: any) => {
    e.preventDefault()
    if (promptable && !isInstalled) {
      setCollapseStatus(false)
    } else if (typeof window !== undefined && appStatus) {
      router.push('/app?trackId=headerCTA')
    } else if (typeof window !== undefined) {
      router.push('/app?trackId=headerCTA')
    } else {
      router.push('/app?trackId=headerCTA')
    }
  }

  const handleBannerClick = (e: any) => {
    e.preventDefault()
    setDisabledClick(true)
    if (promptable && !isInstalled) {
      promptToInstall()
    } else if (typeof window !== undefined && appStatus) {
      router.push('/app?trackId=bannerCTA')
    } else {
      router.push('/app?trackId=bannerCTA')
    }
  }

  const handleBannerCancelClick = (e: any) => {
    e.preventDefault()
    setCollapseStatus(true)
    if (isInstalled) {
      router.push('/app')
    } else {
      router.push('/')
    }
  }

  const makeAppReady = () => {
    // this needs refinement!
    // Use case 1: When I at the desktop --> router.push to /app
    //
    setTimeout(() => {
      if (isInstalled) {
        setAppStatus(true)
        setDisabledClick(false)
      } else {
        setCollapseStatus(true)
        setDisabledClick(false)
      }
    }, 13000)
  }

  useEffect(() => {
    makeAppReady()
  }, [isInstalled])

  let bannerButtonText
  let navButtonText
  if (promptable && !isInstalled) {
    bannerButtonText = t('install-banner.install-button-text', {
      ns: 'app-install',
    })
    navButtonText = t('install-banner.install-button-text', {
      ns: 'app-install',
    })
  } else if (promptable) {
    bannerButtonText = t('install-banner.open-button-text', { ns: 'app-install' })
    navButtonText = t('install-banner.open-button-text', { ns: 'app-install' })
  } else {
    navButtonText = t('button-text')
    bannerButtonText = t('install-banner.sign-in-button-text', { ns: 'app-install' })
  }

  return (
    <div className={[styles['layout-grid'], 'custom-scroll'].join(' ')}>
      <header className={styles['layout-header']}>
        <NavBar
          logoLight={
            <Image
              height={33}
              width={240}
              src="https://uniqs-public-assets-bucket.s3.eu-central-1.amazonaws.com/mailbuilder/logo.png"
              alt="Mail builder logo"
            />
          }
          logoDark={
            <Image
              height={33}
              width={240}
              src="https://uniqs-public-assets-bucket.s3.eu-central-1.amazonaws.com/mailbuilder/logo_dark.png"
              alt="Mail builder logo"
            />
          }
          logoOnClick={() => router.push('/')}
          leftLinks={[
            // { onClick: () => router.push('/features'), title: t('features') },
            { onClick: () => router.push('/contact'), title: t('contact') },
            { onClick: () => router.push('/pricing'), title: t('pricing') },
          ]}
          rightLinks={
            (promptable &&
              !isInstalled && [
                {
                  onClick: () => router.push('/app/signin?trackId=headerCTA'),
                  title: t('sign-in'),
                },
              ]) ||
            []
          }
          primaryButtonText={navButtonText}
          buttonOnClick={handleClick}
        />
      </header>
      <main className={styles['layout-main']}>{children}</main>
      <footer className={styles['layout-footer']}>
        <Card
          aboveTitle={t('card-1.above-title', { ns: 'layout' })}
          mainTitle={t('card-1.main-title', { ns: 'layout' })}
          belowTitle={t('card-1.below-title', { ns: 'layout' })}
          position="center"
          imageDark={
            <QRCode
              text={`${currentOrigin}/${router.locale}/install?trackId=qrScan`}
              radius={0.0}
              fill="#f8f9fa"
              background={null}
            />
          }
          imageLight={
            <QRCode
              text={`${currentOrigin}/${router.locale}/install?trackId=qrScan`}
              radius={0.0}
              fill="#212529"
              background={null}
            />
          }
          imageSize="medium"
          buttonText={navButtonText}
          // @ts-ignore
          buttonOnClick={handleClick}
        />
        <Footer
          hashtag={{ title: t('hashtag'), onClick: () => router.push('/') }}
          links={[
            { title: t('privacy-policy'), onClick: () => router.push('/privacy-policy') },
            { title: t('cookies-policy'), onClick: () => router.push('/cookies-policy') },
            { title: t('terms-of-use'), onClick: () => router.push('/terms-of-use') },
            { title: t('imprint'), onClick: () => router.push('/imprint') },
          ]}
        />
        <p>©{new Date().getFullYear()} UNIQS VGMBH</p>
      </footer>
      {!collapseStatus && (
        <InstallBanner
          icon={<Image src={AppIcon} alt={t('install-banner.app-name', { ns: 'app-install' })} />}
          appName={t('install-banner.app-name', { ns: 'app-install' })}
          author={t('install-banner.author', { ns: 'app-install' })}
          minimalInfo={t('install-banner.minimal-info', { ns: 'app-install' })}
          belowTitle={t('install-banner.below-title', { ns: 'app-install' })}
          description={t('install-banner.description', { ns: 'app-install' })}
          installButtonText={bannerButtonText}
          cancelButtonText={t('install-banner.cancel-button-text', { ns: 'app-install' })}
          cancelButtonAction={handleBannerCancelClick}
          installButtonAction={handleBannerClick}
          disabledButtons={disabledClick}
          installHeaderIos={t('install-banner.install-header-ios')}
          addToHomeScrenText={t('install-banner.add-to-homescreen-text')}
          step1Ios={t('install-banner.setp-1-ios')}
          step2Ios={t('install-banner.step-2-ios')}
        />
      )}
      <div
        className={[
          styles['layout-overlay'],
          collapseStatus ? '' : styles['layout-overlay-open'],
        ].join(' ')}
      />
      <CookieBanner
        onClickCookiePolicy={() => router.push('/cookies-policy')}
        onClickPrivacyPolicy={() => router.push('/privacy-policy')}
      />
    </div>
  )
}
