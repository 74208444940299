/* eslint-disable @next/next/no-img-element */
import { Card, Carousel, useTheme } from '@uniqs-labs/ui-react-lib'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import React, { useEffect, useState } from 'react'
import { IconFileCheck, IconDeviceFloppy, IconUsers, IconCopy } from '@tabler/icons'
import { useRouter } from 'next/router'
import Image from 'next/image'
import Layout from '../components/Layout'
import styles from '../styles/pages/Home.module.css'
import PulsingLogo from '../components/PulsingLogo'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/grid'
import '@uniqs-labs/ui-react-lib/carousel'

// @ts-ignore
export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['home', 'common', 'layout', 'app-install'])),
      // Make sure to pass also the namespace used in Layout Component
    },
  }
}

export default function About() {
  const { t } = useTranslation(['home', 'common'])
  const router = useRouter()
  const { theme } = useTheme() as any

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [theme])

  if (loading) return <PulsingLogo />

  return (
    <Layout>
      <section className={styles['page-grid']}>
        <Card
          aboveTitle={t('card-1.above-title')}
          mainTitle={t('card-1.main-title')}
          belowTitle={t('card-1.below-title')}
          position="left"
          imageSize="medium"
          centerOnSmall
          buttonText={t('card-1.button-text')}
          buttonOnClick={() => router.push('/app?trackId=mainCTA')}
        />
        <div className={styles.image}>
          <Image
            height={2450}
            width={1936}
            src="https://uniqs-public-assets-bucket.s3.eu-central-1.amazonaws.com/mailbuilder/mailbuilder_front_page_example.png"
            alt="Mail Builder preview"
          />
        </div>

        <Carousel
          mainTitle={t('carousel-1.main-title')}
          linkText={t('carousel-1.link-text')}
          onClick={() => router.push('/app')}
          slides={[
            {
              content: (
                <div className={styles['slider-content-wrapper']}>
                  <h3>{t('carousel-1.slide-1.title')}</h3>
                  <div className={styles['carousel-image-container']}>
                    <IconDeviceFloppy size={200} />
                  </div>
                  <h4 style={{ maxWidth: '13rem', margin: '0 auto' }}>
                    {t('carousel-1.slide-1.sub-title')}
                  </h4>
                </div>
              ),
            },
            {
              content: (
                <div className={styles['slider-content-wrapper']}>
                  <h3>{t('carousel-1.slide-2.title')}</h3>
                  <div className={styles['carousel-image-container']}>
                    <IconUsers size={200} />
                  </div>
                  <h4 style={{ maxWidth: '13rem', margin: '0 auto' }}>
                    {t('carousel-1.slide-2.sub-title')}
                  </h4>
                </div>
              ),
            },
            {
              content: (
                <div className={styles['slider-content-wrapper']}>
                  <h3>{t('carousel-1.slide-3.title')}</h3>
                  <div className={styles['carousel-image-container']}>
                    <IconFileCheck size={200} />
                  </div>
                  <h4 style={{ maxWidth: '13rem', margin: '0 auto' }}>
                    {t('carousel-1.slide-3.sub-title')}
                  </h4>
                </div>
              ),
            },
            {
              content: (
                <div className={styles['slider-content-wrapper']}>
                  <h3>{t('carousel-1.slide-4.title')}</h3>
                  <div className={styles['carousel-image-container']}>
                    <IconCopy size={200} />
                  </div>
                  <h4 style={{ maxWidth: '13rem', margin: '0 auto' }}>
                    {t('carousel-1.slide-4.sub-title')}
                  </h4>
                </div>
              ),
            },
          ]}
        />
      </section>
    </Layout>
  )
}
